/**
 * --------------------------------------------------------------------------
 * Framework Macif : languette.js
 * --------------------------------------------------------------------------
 */

import { defineJQueryPlugin } from "./util/index"
import BaseComponent from "./base-component"
import EventHandler from "./dom/event-handler"
import SelectorEngine from "./dom/selector-engine"

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const NAME = "languette"
const DATA_KEY = "mcf.languette"
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = ".data-api"

const EVENT_CHANGE_DATA_API = `change${EVENT_KEY}${DATA_API_KEY}`
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const CLASS_NAME_SELECTED = "selected"
const CLASS_NAME_DISABLED = "disabled"
const CLASS_NAME_LANGUETTE = "mcf-languette"

const SELECTOR_JS_BEHAVIOR = ".mcf-js-behavior"
const SELECTOR_INPUT_CHECKBOX = '.mcf-languette__checkbox input[type="checkbox"]'
const SELECTOR_INPUT_RADIO = '.mcf-languette__radio input[type="radio"]'
const SELECTOR_LANGUETTE_HEADER = ".mcf-languette__header"
const SELECTOR_LANGUETTE = ".mcf-languette"
/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Languette extends BaseComponent {
  constructor(element) {
    super(element)

    this._element = element
  }

  // Getters

  static get NAME() {
    return NAME
  }

  // Public

  toggle() {
    let selector = SELECTOR_INPUT_CHECKBOX
    let input = SelectorEngine.find(selector, this._element)[0]
    if (!input) {
      selector = SELECTOR_INPUT_RADIO
      input = SelectorEngine.find(selector, this._element)[0]
    }

    // si l'input est disabled on ne fait rien
    if (input && !input.disabled) {
      // on check ou on toggle en fonction du type d'input
      if (selector === SELECTOR_INPUT_CHECKBOX) {
        input.checked = !input.checked
        EventHandler.trigger(input, EVENT_CHANGE_DATA_API)
      } else {
        input.checked = true
        const inputRadio = SelectorEngine.find(`input:radio[name='${input.getAttribute("name")}]`)
        EventHandler.trigger(inputRadio, EVENT_CHANGE_DATA_API)
      }
    }
  }

  updateVisual(selector) {
    const input = SelectorEngine.find(selector, this._element)[0]

    if (input) {
      // update du visuel en fonction de l'état disabled de l'input
      if (input.disabled) {
        this._element.classList.add(CLASS_NAME_DISABLED)
      } else {
        this._element.classList.remove(CLASS_NAME_DISABLED)
      }

      // update du visuel en fonction de l'état checked de l'input
      if (input.checked) {
        this._element.classList.add(CLASS_NAME_SELECTED)
      } else {
        this._element.classList.remove(CLASS_NAME_SELECTED)
      }
    }
  }

  // Static
  static jQueryInterface(config) {
    return this.each(function () {
      const data = Languette.getOrCreateInstance(this)

      if (typeof config !== "string") {
        return
      }

      if (data[config] === undefined || config.startsWith("_") || config === "constructor") {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config](this)
    })
  }

  static dataApiToggleHandler(event) {
    const languette = event.target.closest(SELECTOR_LANGUETTE)
    const data = Languette.getOrCreateInstance(languette)

    data.toggle()
  }

  static dataApiUpdateHandler(event) {
    const languette = event.target.closest(SELECTOR_LANGUETTE)
    const data = Languette.getOrCreateInstance(languette)

    data.updateVisual(SELECTOR_INPUT_CHECKBOX)
    data.updateVisual(SELECTOR_INPUT_RADIO)
  }
}

/**
 * ------------------------------------------------------------------------
 * Data Api implementation
 * ------------------------------------------------------------------------
 */

EventHandler.on(
  document,
  EVENT_CLICK_DATA_API,
  `${SELECTOR_JS_BEHAVIOR} ${SELECTOR_LANGUETTE_HEADER}`,
  Languette.dataApiToggleHandler
)
EventHandler.on(
  document,
  EVENT_CHANGE_DATA_API,
  `${SELECTOR_JS_BEHAVIOR} ${SELECTOR_INPUT_CHECKBOX}`,
  Languette.dataApiUpdateHandler
)
EventHandler.on(
  document,
  EVENT_CHANGE_DATA_API,
  `${SELECTOR_JS_BEHAVIOR} ${SELECTOR_INPUT_RADIO}`,
  Languette.dataApiUpdateHandler
)

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 * add .Dropdown to jQuery only if jQuery is present
 */

defineJQueryPlugin(Languette)

export default Languette
