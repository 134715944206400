/**
 * --------------------------------------------------------------------------
 * Framework Macif : lightbox.js
 * --------------------------------------------------------------------------
 */

import { defineJQueryPlugin, getElementFromSelector, typeCheckConfig } from "./util/index"
import EventHandler from "./dom/event-handler"
import BaseComponent from "./base-component"
import Manipulator from "./dom/manipulator"
import Modal from "./modal"
import Carousel from "./carousel"
import SelectorEngine from "./dom/selector-engine"

/**
 * Constants
 */

const NAME = "lightbox"
const DATA_KEY = "mcf.lightbox"
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = ".data-api"
const EVENT_CLICK_DATA_API = `click${EVENT_KEY}${DATA_API_KEY}`

const Default = {
  ...Modal.Default,
  ...Carousel.Default,
  interval: false,
  target: '[data-mcf-toggle="lightbox"]',
  gallery: "",
}

const DefaultType = {
  ...Modal.DefaultType,
  ...Carousel.DefaultType,
  interval: "(number|boolean)",
  target: "string",
  gallery: "string",
}

const ALLOWED_EMBED_TYPES = ["embed", "youtube", "vimeo", "instagram", "url"]
const ALLOWED_MEDIA_TYPES = [...ALLOWED_EMBED_TYPES, "image"]
const SELECTOR_DATA_TOGGLE = '[data-mcf-toggle="lightbox"]'

/**
 * Class definition
 */

class Lightbox extends BaseComponent {
  // Getters
  static get NAME() {
    return NAME
  }

  constructor(element, config) {
    super(element)
    if (typeof element === "string") {
      this._config.target = element
      element = document.querySelector(this._config.target)
    }

    this._config = this._getConfig(config)
    this._hash = this._randomHash()
    this._modalConfig = (() => this._setOptionsFromSettings(Modal.Default))()
    this._carouselConfig = (() => this._setOptionsFromSettings(Carousel.Default))()
    this._carouselElement
    this._modalElement
    this._carousel = Carousel
    this._modal = Modal
    this._type = Manipulator.getDataAttribute(element, "type") || "image"
    this._src = this._getSrc(element)
    this._src = this._type === "image" ? this._src : "embed" + this._src
    this._sources = this._getGalleryItems()
    this._createCarousel()
    this._createModal()
  }

  // Public
  show() {
    document.body.append(this._modalElement)
    this._modal.show()
  }

  hide() {
    this._modal.hide()
  }

  // Private
  _setOptionsFromSettings(obj) {
    return Object.keys(obj).reduce((p, c) => Object.assign(p, { [c]: this._config[c] }), {})
  }

  _getSrc(element) {
    let src =
      Manipulator.getDataAttribute(element, "src") ||
      Manipulator.getDataAttribute(element, "remote") ||
      element.href ||
      "http://via.placeholder.com/1600x900"

    if (!/:\/\//.test(src)) {
      src = window.location.origin + src
    }

    const url = new URL(src)

    if (
      Manipulator.getDataAttribute(element, "footer") ||
      Manipulator.getDataAttribute(element, "caption")
    ) {
      url.searchParams.set(
        "caption",
        Manipulator.getDataAttribute(element, "footer") ||
          Manipulator.getDataAttribute(element, "caption")
      )
    }

    return url.toString()
  }

  _getGalleryItems() {
    let galleryTarget

    if (this._config.gallery) {
      if (Array.isArray(this._config.gallery)) {
        return this._config.gallery
      }

      galleryTarget = this._config.gallery
    } else if (Manipulator.getDataAttribute(this._element, "gallery")) {
      galleryTarget = Manipulator.getDataAttribute(this._element, "gallery")
    }

    SelectorEngine.find(`[data-mcf-gallery="${galleryTarget}"]`)

    const gallery = galleryTarget
      ? SelectorEngine.find(`[data-mcf-gallery="${galleryTarget}"]`).map(
          (v) => `${Manipulator.getDataAttribute(v, "type") ? "embed" : ""}${this._getSrc(v)}`
        )
      : [this._src]
    return gallery
  }

  _getYoutubeId(src) {
    if (!src) return false
    const matches = src.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/)
    return matches && matches[2].length === 11 ? matches[2] : false
  }

  _getInstagramEmbed(src) {
    if (/instagram/.test(src)) {
      src += /\/embed$/.test(src) ? "" : "/embed"
      return `<iframe src="${src}" style="max-width: 500px; transform: translateX(-50%); left:50%;" frameborder="0" scrolling="no" allowtransparency="true"></iframe>`
    }
  }

  _isEmbed(src) {
    const regex = new RegExp(ALLOWED_EMBED_TYPES.join("|"))
    const isEmbed = regex.test(src)
    const isImg = /\.(png|jpe?g|gif|svg|webp)/.test(src)
    return isEmbed || !isImg
  }

  _createCarousel() {
    const template = document.createElement("template")

    const slidesHtml = this._sources
      .map((src, i) => {
        src = src.replace(/\/$/, "")
        let onload = ""
        onload += /\.png/.test(src) ? `this.add.previousSibling.remove()` : ""
        let inner = `<img src="${src}" class="mcf-d--block mcf-w--100 mcf-h--100 mcf-img--fluid" style="z-index: 1; object-fit: contain;" onload="${onload}" />`
        let attributes = ""
        const instagramEmbed = this._getInstagramEmbed(src)
        const youtubeId = this._getYoutubeId(src)
        if (this._isEmbed(src)) {
          if (src.startsWith("embed")) src = src.slice(5)

          if (youtubeId) {
            src = `https://www.youtube.com/embed/${youtubeId}`
            attributes =
              'title="YouTube video player" frameborder="0" allow="accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture"'
          }

          inner = instagramEmbed || `<iframe src="${src}" ${attributes} allowfullscreen frameborder="0"></iframe>`
        }

        const spinner = `<div class="mcf-position--absolute mcf-top--50 mcf-start--50 mcf-translate--middle mcf-text--white"><div class="mcf-spinner-border" style="width: 3rem height: 3rem" role="status"></div></div>`

        const params = new URLSearchParams(src.split("?")[1])
        let caption = ""
        if (params.get("caption")) {
          caption = `<p class="lightbox-caption mcf-m--0 mcf-p--2 mcf-text--center mcf-text--white mcf-small"><em>${params.get(
            "caption"
          )}</em></p>`
        }
        return `
				<div class="mcf-carousel__item ${!i ? "active" : ""}" style="min-height: 100px">
					${spinner}
					<div class="mcf-ratio mcf-ratio--16x9" style="background-color: #000;">${inner}</div>
					${caption}
				</div>`
      })
      .join("")

    const controlsHtml =
      this._sources.length < 2
        ? ""
        : `
			<button class="mcf-carousel__control-prev mcf-h--75 mcf-m--auto" type="button" data-mcf-target="#lightboxCarousel-${this._hash}" data-mcf-slide="prev">
				<span class="mcf-carousel__control-prev__icon" aria-hidden="true"></span>
				<span class="mcf-sr-only">Précédent</span>
			</button>
			<button class="mcf-carousel__control-next mcf-h--75 mcf-m--auto" type="button" data-mcf-target="#lightboxCarousel-${this._hash}" data-mcf-slide="next">
				<span class="mcf-carousel__control-next__icon" aria-hidden="true"></span>
				<span class="mcf-sr-only">Suivant</span>
			</button>`

    const html = `
			<div id="lightboxCarousel-${this._hash}" class="lightbox-carousel mcf-carousel mcf-slide" data-mcf-ride="carousel" data-mcf-interval="${this._carouselConfig.interval}">
				<div class="mcf-carousel__inner">
					${slidesHtml}
				</div>
				${controlsHtml}
			</div>`

    template.innerHTML = html.trim()
    this._carouselElement = template.content.firstChild
    this._carousel = new Carousel(this._carouselElement, this._carouselConfig)
    this._carousel.to(this._sources.includes(this._src) ? this._sources.indexOf(this._src) : 0)
    return this._carousel
  }

  _createModal() {
    const template = document.createElement("template")
    const html = `
			<div class="mcf-modal lightbox fade" id="lightboxModal-${this._hash}" tabindex="-1" aria-hidden="true">
				<div class="mcf-modal__dialog mcf-modal__dialog--centered mcf-modal--xl">
					<div class="mcf-modal__content mcf-bg--transparent mcf-p--0">
						<div class="mcf-modal__body mcf-p--0">
							<button type="button" class="close mcf-position--absolute mcf-p--3" data-mcf-dismiss="modal" aria-label="Fermer" style="z-index: 2; font-size: 32px; right: 0;"></button>
						</div>
					</div>
				</div>
			</div>`

    template.innerHTML = html.trim()

    this._modalElement = template.content.firstChild
    this._modalElement.querySelector(".mcf-modal__body").append(this._carouselElement)
    this._modalElement.addEventListener("hidden.mcf.modal", () => this._modalElement.remove())
    this._modalElement
      .querySelector("[data-mcf-dismiss]")
      .addEventListener("click", () => this._modal.hide())
    this._modal = new Modal(this._modalElement, this._modalConfig)
    return this._modal
  }

  _randomHash(length = 8) {
    return Array.from({ length }, () => Math.floor(Math.random() * 36).toString(36)).join("")
  }

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...(typeof config === "object" ? config : {}),
    }
    typeCheckConfig(NAME, config, DefaultType)
    return config
  }

  // Static
  static jQueryInterface(config) {
    return this.each(function () {
      const data = Lightbox.getOrCreateInstance(this)

      if (typeof config !== "string") {
        return
      }

      if (data[config] === undefined || config.startsWith("_") || config === "constructor") {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config](this)
    })
  }
}

/**
 * Data API implementation
 */

EventHandler.on(document, EVENT_CLICK_DATA_API, SELECTOR_DATA_TOGGLE, function (event) {
  event.preventDefault()

  const data = Lightbox.getOrCreateInstance(this)

  data.show(this)
})

/**
 * jQuery
 */

defineJQueryPlugin(Lightbox)

export default Lightbox
